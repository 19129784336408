





















import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import Tabs from "@monorepo/app/src/views/Profile/components/ProfileTabs.vue";
import { defineComponent } from "@vue/composition-api";
import { breadcrumbs } from "@monorepo/app/src/views/Profile/constants/breadcrumbs";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/profileView/viewTitle";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ProfileView",
  components: {
    Breadcrumbs,
    Tabs,
    info: () =>
      import(
        /* webpackChunkName: "ProfileViewInfoTab" */
        "@monorepo/app/src/views/Profile/components/info/InfoView.vue"
      ),
    notifications: () =>
      import(
        /* webpackChunkName: "ProfileViewNotificationsTab" */
        "@monorepo/app/src/views/Profile/components/notifications/NotificationsView.vue"
      ),
    audit: () =>
      import(
        /* webpackChunkName: "ProfileViewAuditTab" */
        "@monorepo/app/src/views/Profile/components/audit/AuditView.vue"
      ),
  },
  data() {
    return {
      tab: null,
      breadcrumbs,
      viewTitle,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation"]),
  },
});
