export const tabsTypes = {
  info: {
    title: "Данные профиля",
    url: "info",
  },
  notifications: {
    title: "Уведомления",
    url: "notifications",
  },
  audit: {
    title: "Журнал событий",
    url: "audit",
  },
};
