


















import { defineComponent } from "@vue/composition-api";
import { tabsTypes } from "@monorepo/utils/src/variables/projectsData/profileView/tabsTypes";

export default defineComponent({
  name: "ProfileTabs",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      tabsTypes,
    };
  },
  computed: {
    tabsTypesQty(): number {
      return Object.keys(this.tabsTypes).length;
    },
  },
});
